import { ParentLink } from '@/pages_old/Tasks/Tasks';
import { DropDown } from '@/components';
import {
  ButtonGroup,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import Moment from 'moment';
import { Link } from "react-router-dom";
import { FileItem } from "./types";

type onDeleteType = (id: string) => void;

type RecentProps = {
  recentFiles: Array<FileItem>;
  onDelete?: onDeleteType;
};

    // <ButtonGroup className="d-block">
    //   <Dropdown align="end">
    //     <Dropdown.Toggle className="table-action-btn dropdown-toggle arrow-none btn btn-light btn-xs">
    //     </Dropdown.Toggle>
    //     <Dropdown.Menu>
    //       <Dropdown.Item>
    //         <i className="mdi mdi-share-variant me-2 text-muted vertical-middle"></i>
    //         Share
    //       </Dropdown.Item>
    //       <Dropdown.Item>
    //         <i className="mdi mdi-link me-2 text-muted vertical-middle"></i>
    //         Get Sharable Link
    //       </Dropdown.Item>
    //       <Dropdown.Item>
    //         <i className="mdi mdi-pencil me-2 text-muted vertical-middle"></i>
    //         Rename
    //       </Dropdown.Item>
    //       <Dropdown.Item>
    //         <i className="mdi mdi-download me-2 text-muted vertical-middle"></i>
    //         Download
    //       </Dropdown.Item>
    //       <Dropdown.Item>
    //         <i className="mdi mdi-delete me-2 text-muted vertical-middle"></i>
    //         Remove
    //       </Dropdown.Item>
    //     </Dropdown.Menu>
    //   </Dropdown>
    // </ButtonGroup>

const Dropdown = ({ file, onDelete }: { file: FileItem, onDelete?: onDeleteType }) => {
  var menuItems = [
    file.contentType ? {
      label: "Download",
      icon: 'mdi-download',
      href: file.link || ''
    } : {
      label: "Go",
      icon: 'mdi-open-in-new',
      href: file.link || ''
    },
    {
      hasDivider: true,
      variant: 'primary',
      label: "Remove",
      icon: 'mdi-delete',
      onClick: () => onDelete && onDelete(file.id)
    }
  ]

  return(
    <DropDown menuItems={menuItems}>
      <i className="mdi mdi-dots-horizontal"></i>
    </DropDown>
  )
}

const Recent = ({ recentFiles, onDelete }: RecentProps) => {
  return (
    <div className="mt-3">
      <Table responsive className="table table-centered table-nowrap mb-0">
        <thead className="table-light">
          <tr>
            <th className="border-0"></th>
            <th className="border-0">Name</th>
            <th className="border-0">Last Modified</th>
            <th className="border-0">Category</th>
            <th className="border-0">Parent</th>
            <th className="border-0">Size</th>
            <th className="border-0" style={{ width: "80px" }}>
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {recentFiles.map((file) => {
            const { owners = [] } = file;
            return (
              <tr key={file.id}>
                <td>
                  <div className="avatar-sm">
                    <span className="avatar-title rounded">
                      {file.contentType ? (file.contentType || "").split("/")[1].substring(0,3) : 'www'}
                    </span>
                  </div>
                </td>
                <td>
                  <span className="fw-semibold">
                    <Link to={file.link || ''} className="text-reset" target="_blank">
                      {file.name}
                    </Link>
                  </span>
                </td>
                <td>
                  <p className="mb-0">{Moment(file.modifiedDate).format("MMM D, YYYY")}</p>
                  {file.modifiedBy && <span className="font-12">by {file.modifiedBy}</span>}
                </td>
                <td>{file.type}</td>
                <td>{owners.map((owner) => (
                  <ParentLink owner={owner} key={owner.id} />
                ))}</td>
                <td>{file.size}</td>
                <td>
                  <Dropdown file={file} onDelete={onDelete} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default Recent;
